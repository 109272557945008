<template>
  <div class="page page-xranking-all-jobs">
    <PageHeader
      :icon="$t('JOB.ICON')"
      :title="$t('JOB.TITLES')"
      class="pt-2"
    >
      <template>
        <!-- this button is not doing anything right now, no need for it to be displayed in its current state -->
        <!-- <v-btn
          small
          depressed
          color="accent"
          class="page-xranking-all-jobs__button-matching ml-2"
        >
          <v-icon>mdi-magnify-scan</v-icon>
          {{ $t('COMMON.TOOLTIP.MATCHING') }}
        </v-btn> -->
        <v-btn
          small
          depressed
          color="success"
          class="page-xranking-all-jobs__button-upload ml-2"
          @click="searchByOffer"
        >
          <v-icon>mdi-plus</v-icon>
          {{ $t("COMMON.ADD_JOB") }}
        </v-btn>
      </template>
      <template slot="nav">
        <small><strong>{{$tc('JOB.TITLE_COUNT', jobs.length)}}</strong></small>
      </template>
    </PageHeader>

    <List
      v-if="(noData === false && loading === true) || jobs.length"
      checkboxClass="page-xranking-all-jobs__checkbox"
      :items="currentPageData"
      :count="jobs.length"
      :offset="offset"
      :limit="limit"
      :selected="selected"
      :pagination="true"
      :loading="loading"
      @page="changePage"
      @reload="validateRaOrg"
      @click="goToItem"
      @select="selectItems"
    >
      <template v-slot:header="">
        <div class="col-4">
          <v-text-field
            ref="where.title"
            hide-details
            outlined
            dense
            clearable
            prepend-inner-icon="mdi-magnify"
            :placeholder="$t('COMMON.SEARCH')"
            @keyup="searchItems"
            @click:clear="searchItems"
          />
        </div>
      </template>

      <template v-slot:selections>
        <v-col cols="12" class="pt-0 pl-0">
          <div><small><strong>{{ $tc('COMMON.SELECTED', +selected.length) }}</strong></small></div>
          <v-btn-toggle dark rounded>
            <v-btn
              small
              rounded
              depressed
              color="error"
              @click="toogleDialogRemove(true)"
            >
              <v-icon size="20" class="mr-2">mdi-delete</v-icon>
              {{ $t('COMMON.REMOVE') }}
            </v-btn>
            <v-btn
              small
              rounded
              depressed
              color="secondary custom black--text"
              @click="selectItems([])"
            >
              <v-icon size="20" class="mr-2" color="black">mdi-close</v-icon>
              {{ $t('COMMON.CANCEL') }}
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </template>

      <template v-slot:item-list="item">
        <v-list-item-content>
          <v-list-item-title v-if="titleItem(item)" class="primary--text">
            <strong>{{ titleItem(item) }}</strong>
          </v-list-item-title>

          <v-list-item-subtitle>
            <v-chip
              v-if="description(item)"
              small
              label
              class="page-xprogrammatic-all-jobs__item-subtitle pl-1 ml-1"
            >
              <v-icon
                v-if="description(item)"
                class="mr-1"
                size="14"
              >
                mdi-map-marker
              </v-icon>
              {{ description(item) }}
            </v-chip>
            <v-chip
              v-if="description(item)"
              small
              label
              class="page-xprogrammatic-all-jobs__item-subtitle pl-1 ml-1"
            >
              <v-icon
                v-if="titleCompany(item)"
                class="mr-1"
                size="12"
              >
                {{ $t('COMPANY.ICON') }}
              </v-icon>
              {{ titleCompany(item) }}
            </v-chip>
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action class="ml-3 date">
          {{ $moment(item.created_at).fromNow() }}
        </v-list-item-action>

        <v-list-item-action>
          <div>
            <v-tooltip bottom transition="none">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  depressed
                  class="px-1"
                  style="min-width: 36px !important"
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click.stop="goToMatching(item)"
                >
                  <v-icon color='accent'>mdi-magnify-scan</v-icon>
                </v-btn>
              </template>
              {{ $t('COMMON.TOOLTIP.MATCHING') }}
            </v-tooltip>
            <v-tooltip bottom transition="none">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  class="ml-2"
                  @click.stop="loadFileDetails(item.id)"
                >
                  <v-icon>mdi-download-outline</v-icon>
                </v-btn>
              </template>
              {{ $t('COMMON.TOOLTIP.DOWNLOAD') }}
            </v-tooltip>
            <v-tooltip bottom transition="none">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  class="page-xranking-all-jobs__actions-delete ml-2"
                  @click.stop="openRemoveDialog(item)"
                >
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </template>
              {{ $t('COMMON.TOOLTIP.DELETE') }}: {{ `${getTitle(item)} ` }} {{$t('JOB.TITLE')}}
            </v-tooltip>
          </div>
        </v-list-item-action>
      </template>
    </List>

    <div
      v-if="!jobs.length && noData"
      class="page-xranking-all-jobs__no-data  mt-10"
    >
      {{ $t("COMMON.NO_DATA") }}
    </div>

    <DialogRemove
      :value="showDialogRemove"
      :items="selected"
      item-text="id"
      @confirm="confirmRemoveItems"
      @close="toogleDialogRemove(false)"
      @select="selectItems"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex"
import Loading from '@/components/Loading'
import PageHeader from '@/components/PageHeader'
import List from '@/components/List'
import DialogRemove from '@/components/DialogRemove'

export default {
  name: "PageXrankingAllCvs",
  components: {
    Loading,
    PageHeader,
    List,
    DialogRemove,
  },
  data: (_this) => ({
    selected: [],
    items: [],
    loading: false,
    noData: false,
    count: 0,
    offset: 0,
    limit: 10,
    page: 1,
    showDialogRemove: false,
  }),
  computed: {
    ...mapState('user', {
      userOfficeId: state => state.user.office_id,
    }),

    ...mapGetters({
      token: 'user/getToken',
      jobs: 'job/getJobs',
    }),

    currentPageData() {
      const start = (this.page - 1) * this.limit
      const end = start + this.limit
      const cloneItems = JSON.parse(JSON.stringify(this.jobs))

      return cloneItems.slice(start, end)
    },
  },
  mounted() {
    this.validateRaOrg()
  },
  methods: {
    ...mapActions({
      addJobMatch: 'job/addJobMatch',
      addJobs: 'job/addJobs',
    }),

    titleItem(item) {
      return item.description && item.description.length &&
      item.description[0].occupations && item.description[0].occupations.length &&
      item.description[0].occupations[0].plaintext ? item.description[0].occupations[0].plaintext : item.description[0].plaintext || ''
    },

    description(item) {
      const state = item.description && item.description.length &&
        item.description[0].city && item.description[0].city.length &&
        item.description[0].city[0].ref && item.description[0].city[0].ref.state ? `${item.description[0].city[0].ref.state}, ` : ''

      const postalCode = item.description && item.description.length &&
        item.description[0].city && item.description[0].city.length &&
        item.description[0].city[0].ref && item.description[0].city[0].ref.postal_code ? item.description[0].city[0].ref.postal_code : ''

      return `${state}${postalCode}`
    },

    titleCompany(item) {
      return item.company && item.company.length &&
        item.company[0].plaintext ?  item.company[0].plaintext : ''
    },

    goToItem (item) {
      item = item ? item : this.selected[0]
      this.$router.push(`/xranking/offers/${item.id}`)
    },

    goToMatching (item) {
      const { id } = item ? item : this.selected[0]
      this.addJobMatch([])
      this.$router.push(`/xranking/matching/offers/${id}`)
    },

    selectItem (item) {
      for (let i=0, len = this.selected.length; i < len; i++) {
        if (this.selected[i].id === item.id) return this.selected.splice(i,1)
      }

      return this.selected.push(item)
    },

    selectItems (arr) {
      this.selected = arr
    },

    changePage (page) {
      this.page = +page
      this.searchItems()
    },

    validateRaOrg() {
      this.loading = true

      this.$services.xusers_ra.organization.describe()
        .then((response) => {
          if (response.data.organization.id !== this.userOfficeId) {
            this.addJobs([])
            this.noData = true
            this.$store.dispatch('messenger/add', {type: 'warning', code: 'XRANKING.MISSING_OFFICE_ORG_MAPPING',
              message: `Office ${this.userOfficeId} does not have a corresponding xusers organization mapped`, time: 5000 })
          } else {
            this.searchItems()
          }
        })
        .catch(e => {
          this.addJobs([])
          this.noData = true
          let message = `Error validating organization for office ${this.userOfficeId}`
          if (e.error && e.error.code && e.error.message) {
            message = `${e.error.code} - ${e.error.message}`
          }
          this.$store.dispatch('messenger/add', {type: 'error', code: 'XRANKING.ORGANIZATION_ERROR',
            message, time: 5000 })
        })
    },

    searchItems() {
      const query_offers = {
        page: 1,
        size: 1000,
        sort: '-date',
      }

      this.$services.xranking_ra.v2_offers.get(query_offers)
        .then(({data: { data}}) => {
          this.addJobs(data || [])
        })
        .finally(() => {
          this.loading = false
          this.noData = true
        })

      this.offset = this.limit * this.page - 1
    },

    searchByOffer() {
      this.$router.push('/xranking/upload/offer')
    },

    async loadFileDetails(fileId) {
      let data

      try {
        const { data: responseData } = await this.$services.xxlsfiles_ra.file.detailes(fileId)
        data = responseData
      } catch({ error: { message, details: { Error }}}) {
        const msg = `${message}. ${Error || ''}`
        this.$store.dispatch('messenger/add', {type: 'error', code: 'XRANKING.SERVER_ERROR',
          message: msg, time: 5000 })
      }

      if (data.status === 'processed') {
        const link = document.createElement('a')
        const downloadFileName = `${data.original_filename.split('.').shift()}.xlsx`
        const windowUrl = window.URL || window.webkitURL
        const req = new window.XMLHttpRequest()
        req.responseType = 'blob'
        req.open(
          'GET', `${process.env.VUE_APP_KONG_URL}/xranking/v2/jobs/${fileId}?exportFormat=xlsx&exportLang=${this.$t('COMMON.ANOTHER.LANG')}`
        )
        req.setRequestHeader('authorization', `Bearer ${this.token}`)
        req.onload = function onload() {
          const blob = req.response // Note: not oReq.responseText
          const url = windowUrl.createObjectURL(blob)
          link.setAttribute('href', url)
          link.setAttribute('download', downloadFileName)
          link.click()
          windowUrl.revokeObjectURL(url)
        }
        req.send()
      }
    },

    getTitle({ title, content }) {
      const { plaintext: jobName} = title
      return jobName || content || ''
    },

    openRemoveDialog(item) {
      this.selectItem(item)
      this.toogleDialogRemove(true)
    },

    toogleDialogRemove(state) {
      this.showDialogRemove = state
    },

    async confirmRemoveItems() {
      const ids = this.selected.map(v => v.id)

      for (let i = 0; i < ids.length; i++) {
        try {
          this.loading = true
          const { data: { result }, status } = await this.$services.xrankingact_ra.job.remove(ids[i])
          this.selected = this.selected.filter(item => item.id !== ids[i])
          this.loading = false
          this.$store.dispatch('messenger/add', {type: 'success', code: 'XRANKING.DELETE_JOB_SUCCESSFULLY', message: result, time: 3000 })
          await this.searchItems()
        } catch({ message, code }) {
          this.loading = false
          this.$store.dispatch('messenger/add', {type: 'error', code, message, time: 5000 })
          await this.searchItems()
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/mixins/add-button-styles.scss';

$margin-top: 10px;
$border-radius: 10px;
$gap: 5px;

.page-xranking-all-jobs {
  position: relative;

  .v-toolbar__content {
    align-items: center;
  }
}

.page-xranking-all-jobs__button-upload {
  @include add-button-styles($margin-top, $border-radius, $gap);
}

.page-xranking-all-jobs__button-matching {
  @include add-button-styles($margin-top, $border-radius, $gap);
}

.page-xranking-all-jobs__actions-delete.theme--light.v-btn.v-btn--icon  {
  color: $color-pink;

  &:hover {
    color: $color-pink-hover;
  }
}

.page-xranking-all-jobs__no-data {
  display: flex;
  justify-content: center;
  font-size: 24px;
  color: $color-dark-blue;
}
</style>
